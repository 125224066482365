.Reasons {
    padding: 0 2rem;
    display: flex;
    gap: 2rem; 
    width: 100%;
}

.left-r {
    width: 50%;
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}

.left-r > img {
    object-fit: cover;
}

.left-r>:nth-child(1) {
    width: 12rem;
    grid-row: 1/3;
    height: 28rem;

    width: 100%;
    height: 100%;

}

.left-r>:nth-child(2) {
    width: auto;
    height: 16rem;
    grid-column: 2/4;

    width: 100%;
    height: 100%;
}

.left-r>:nth-child(3) {
    width: 14rem;
    grid-row: 2;
    grid-column: 2/3;

    width: 100%;
    height: 100%;
}

.left-r>:nth-child(4) {
    width: 10rem;
    grid-row: 2;
    grid-column: 3/4;
    height: 11.2rem;

    width: 100%;
    height: 100%;
}


.right-r {
    width: 50%;
    justify-content: center;
    font-size: 25px;
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.right-r>span {
    font-weight: bold;
    color: var(--orange);
}

.right-r>div {
    color: white;
    font-size: 3rem;
    font-weight: bold;   
}

.details-r {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.details-r>div {
    display: flex;
    font-size: 1rem;
    gap: 1rem;
}

.details-r>div>img {
    width: 2rem;
    height: 2rem;
}

.partners {
    display: flex;
    gap: 1rem;
}

.partners>img {
    width: 2.5rem;
}

#our-partners {
    color: var(--gray);
    font-weight: normal;
}


@media screen and (max-width: 768px) {
    .Reasons { 
        flex-direction: column;
    }

    .left-r {
        grid-auto-rows: auto;
        overflow: hidden;
        width: 95% !important;
        row-gap: 10px;
        column-gap: 10px;
        align-self: flex-start !important;

    }

    .left-r>img:nth-child(1) {
        width: 100%;
        height: 100%;
    }

    .left-r>img:nth-child(2) {
        width: 100%;
        height: 100%;
    }

    .left-r>img:nth-child(3) {
        width: 100%;
        height: 100%;

    }

    .left-r>img:nth-child(4) {
        width: 100%;
        height: 100%;
    }

    .left-r {
        overflow-y: hidden;
    }
    
    .right-r {
        align-self: flex-start !important;
    }
}


@media screen and (max-width: 1200px) {
    .Reasons {
        display: flex;
        flex-direction: column;
    }

    .left-r {
        width: 80%;
        gap: 20px;
        row-gap: 20;
        column-gap: 20px;
        align-self: center;
    }

    .left-r>img:nth-child(1) {
        width: 100%;
        height: 100%;
    }

    .left-r>img:nth-child(2) {
        width: 90%;
        height: 100%;
    }

    .left-r>img:nth-child(3) {
        width: 100%;
        height: 100%;

    }

    .left-r>img:nth-child(4) {
        width: 80%;
        height: 100%;
    }

    .right-r {
        align-self: center;
        width: 80%;
        margin: 50px 0px;
    }

}